// ヘッダーのタブでホームに該当するページを表示する際に使用する

import { useESArticles, useESBooks } from 'web_core/hooks'
import { deserializeArray } from 'web_core/utils'
import { HomePageProps } from '~/static_props/home'

export const useHomePageProps = ({
  trendArticles: staticTrendArticles,
  trendBooks: staticTrendBooks,
  popularProfiles: staticPopularProfiles,
  categories: staticCategories,
}: HomePageProps) => {
  const { articles: trendArticles } = useESArticles(
    {
      type: 'trend',
      locale: 'ja',
    },
    staticTrendArticles ? deserializeArray(staticTrendArticles, {}) : undefined
  )

  const { books: trendBooks } = useESBooks(
    {
      type: 'trend',
      locale: 'ja',
    },
    staticTrendBooks ? deserializeArray(staticTrendBooks, {}) : undefined
  )

  return {
    trendArticles,
    trendBooks,
    staticPopularProfiles,
    staticCategories,
  }
}
