import Image from 'next/image'
import Link from 'next/link'
import { Alert } from 'react-bootstrap'
import { FiTrendingUp } from 'react-icons/fi'
import { ArticleList } from 'web_core/assets/article/List'
import { BookList } from 'web_core/assets/book'
import { useAccount } from 'web_core/context/account'
import { ESArticle, ESBook } from 'web_core/elastic_search'
import { IS_PROD, logEvent } from 'web_core/utils'
import { homeBreadcrumb } from '~/utils'
import { Heading } from '../../Utils/Heading'
import { RecommendedArticlesSection } from './RecommendArticles'

type Props = {
  trendArticles: ESArticle[]
  trendBooks: ESBook[]
}

export const Home = ({ trendArticles, trendBooks }: Props) => {
  const { account } = useAccount()
  return (
    <>
      {(account === null || account?.plan === 'Free') && (
        <>
          <div className="d-none d-sm-block d-xl-none w-100 mb-4">
            <Link href="/pricing" passHref>
              <a
                onClick={() => {
                  // バナーがクリックされた際のイベント収集
                  logEvent('tap_home_banner')
                }}
              >
                <Image
                  src="/images/premium_banner_1734x328.png"
                  alt="数学の情報共有をもっと円滑に! Mathlog Premiumプラン! 1週間無料体験"
                  layout="responsive"
                  width={1734}
                  height={328}
                />
              </a>
            </Link>
          </div>
          <div className="d-sm-none d-xl-none w-100 mb-4">
            <Link href="/pricing" passHref>
              <a
                onClick={() => {
                  // バナーがクリックされた際のイベント収集
                  logEvent('tap_home_banner')
                }}
              >
                <Image
                  src="/images/premium_banner_1364x354.png"
                  alt="数学の情報共有をもっと円滑に! Mathlog Premiumプラン! 1週間無料体験"
                  layout="responsive"
                  width={1364}
                  height={354}
                />
              </a>
            </Link>
          </div>
        </>
      )}
      {!IS_PROD && (
        <Alert variant="danger" className="py-2">
          こちらは開発環境です。Mathlogの運営関係者以外の方はご利用頂けません。アカウントの作成やログインが確認された場合は厳正に対処します。
        </Alert>
      )}
      {homeBreadcrumb.toReactNode()}
      <Heading title="Mathlog" level="h1" />
      <p className="mb-6">
        Mathlogは数学の学びを自由に記録・共有できるWebサービスです。学びの記録や閲覧による学習はもちろん、数学に興味を持つ人々との議論や問題解決を通じた交流もできます。
      </p>
      <div className="d-flex flex-column gap-7">
        {trendArticles.length > 0 && (
          <section>
            <Heading title="最近人気の記事" icon={FiTrendingUp} level="h2" />
            <ArticleList
              articles={trendArticles}
              className="grid g-cols-1 g-lg-cols-2"
            />
          </section>
        )}
        {trendBooks.length > 0 && (
          <section>
            <Heading title="最近人気の本" icon={FiTrendingUp} level="h2" />
            <BookList books={trendBooks} />
          </section>
        )}
        <RecommendedArticlesSection />
      </div>
    </>
  )
}
