import { IconType } from 'react-icons'
import { IconWrapper } from 'web_core/assets/IconWrapper'

type Props = {
  icon?: IconType
  level?: 'h1' | 'h2'
  title: string
  className?: string
}

export const Heading = ({
  icon: Icon,
  level = 'h1',
  title,
  className,
}: Props) => {
  const TitleContent = Icon ? (
    <IconWrapper suffix={title}>
      <Icon />
    </IconWrapper>
  ) : (
    title
  )
  return level === 'h1' ? (
    <h1 className={`h4 ${className !== undefined ? className : ''}`}>
      {TitleContent}
    </h1>
  ) : (
    <h2 className={`h5 ${className !== undefined ? className : ''}`}>
      {TitleContent}
    </h2>
  )
}
