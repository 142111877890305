import Link from 'next/link'
import {
  Breadcrumb as BootstrapBreadcrumb,
  BreadcrumbItem,
} from 'react-bootstrap'

export class Breadcrumb {
  public readonly label: string
  public readonly path: string | null
  public readonly parent: Breadcrumb | undefined

  constructor(label: string, path: string | null, parent?: Breadcrumb) {
    this.label = label
    this.path = path
    this.parent = parent
  }

  private toReactNodeImpl(): React.ReactNode {
    return (
      <>
        {this.parent ? this.parent.toReactNodeImpl() : <></>}
        {this.path === '' || this.path === null ? (
          <>
            <BreadcrumbItem active>{this.label}</BreadcrumbItem>
          </>
        ) : (
          <Link href={this.path} passHref>
            <BreadcrumbItem>{this.label}</BreadcrumbItem>
          </Link>
        )}
      </>
    )
  }

  toReactNode(): React.ReactNode {
    return (
      <BootstrapBreadcrumb className="small mb-1">
        {this.parent ? this.parent.toReactNodeImpl() : <></>}
        <BreadcrumbItem active>{this.label}</BreadcrumbItem>
      </BootstrapBreadcrumb>
    )
  }
}
