import { Breadcrumb } from 'web_core/assets/Breadcrumb'
import { Category } from 'web_core/firebase'
import { Locale } from 'web_core/types'

// ホーム
export const homeBreadcrumb = new Breadcrumb('ホーム', '/')

// 記事のランキング
export const articleRankingBreadcrumb = new Breadcrumb(
  '記事のランキング',
  '/ranking/articles',
  homeBreadcrumb
)
export const annuallyArticleRankingBreadcrumb = (year: string) =>
  new Breadcrumb(
    `${year}年`,
    `/ranking/articles/${year}`,
    articleRankingBreadcrumb
  )
export const monthlyArticleRankingBreadcrumb = (year: string, month: string) =>
  new Breadcrumb(
    `${month}月`,
    `/ranking/articles/${year}/${month}`,
    annuallyArticleRankingBreadcrumb(year)
  )

// 本のランキング
export const bookRankingBreadcrumb = new Breadcrumb(
  '本ランキング',
  '/ranking/books',
  homeBreadcrumb
)
export const annuallyBookRankingBreadcrumb = (year: string) =>
  new Breadcrumb(`${year}年`, `/ranking/books/${year}`, bookRankingBreadcrumb)
export const monthlyBookRankingBreadcrumb = (year: string, month: string) =>
  new Breadcrumb(
    `${month}月`,
    `/ranking/books/${year}/${month}`,
    annuallyBookRankingBreadcrumb(year)
  )

// 記事のタイムライン
export const articleTimelineBreadcrumb = new Breadcrumb(
  '記事のタイムライン',
  '/timeline/articles',
  homeBreadcrumb
)

// 記事のタイムライン
export const bookTimelineBreadcrumb = new Breadcrumb(
  '本のタイムライン',
  '/timeline/books',
  homeBreadcrumb
)

// 記事のカテゴリ
export const articleCategoryBreadcrumb = (category: Category, locale: Locale) =>
  new Breadcrumb(
    category.localized_name[locale],
    `/categories/${category.id}/articles`,
    homeBreadcrumb
  )

// 本のカテゴリ
export const bookCategoryBreadcrumb = (category: Category, locale: Locale) =>
  new Breadcrumb(
    category.localized_name[locale],
    `/categories/${category.id}/articles`,
    homeBreadcrumb
  )

// 検索
export const searchBreadcrumb = new Breadcrumb('検索', null, homeBreadcrumb)

// 記事の検索
export const articleSearchBreadcrumb = new Breadcrumb(
  '記事',
  '/articles',
  searchBreadcrumb
)

// 本の検索
export const bookSearchBreadcrumb = new Breadcrumb(
  '本',
  '/books',
  searchBreadcrumb
)

// ユーザの検索
export const userSearchBreadcrumb = new Breadcrumb(
  'ユーザ',
  '/users',
  searchBreadcrumb
)
