import { useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { ArticleList } from 'web_core/assets/article'
import { LoadingSpinner } from 'web_core/assets/spinners'
import { useESArticles } from 'web_core/hooks'
import { Heading } from '~/components/Utils/Heading'

type Props = {
  categoryId?: string
}

export const RecommendedArticlesSection = ({ categoryId }: Props) => {
  const seed = useRef<number>(Math.random())

  const { articles, loadMore, isReachingEnd, isLoadingMore } = useESArticles({
    type: 'recommendation',
    locale: 'ja',
    seed: seed.current,
    category_id: categoryId ?? undefined,
  })

  return (
    <>
      <section>
        <Heading title="おすすめの記事" level="h2" />
        <InfiniteScroll
          loadMore={async () => {
            if (isLoadingMore === false && isReachingEnd === false)
              await loadMore()
          }}
          hasMore={isReachingEnd === false}
          loader={<LoadingSpinner />}
        >
          {articles && (
            <ArticleList
              articles={articles}
              className="grid g-cols-1 g-lg-cols-2"
            />
          )}
        </InfiniteScroll>
      </section>
    </>
  )
}
