import { NextPage } from 'next'
import { DefaultLayout } from '~/components/Layout/Default'
import { Home } from '~/components/Page/Home'
import { useHomePageProps } from '~/hooks/useHomePageProps'
import { getHomePageStaticProps, HomePageProps } from '~/static_props/home'

export const getStaticProps = getHomePageStaticProps

const Page: NextPage<HomePageProps> = (staticProps) => {
  const { trendArticles, trendBooks, staticCategories, staticPopularProfiles } =
    useHomePageProps(staticProps)
  return (
    <DefaultLayout
      head={{
        title: '数学特化の情報共有サービス',
      }}
      navLinks={{ pc: true, tablet: true, sp: true, active: 'home' }}
      categoriesMenu={{ pc: true, sp: true, spFixed: true }}
      staticCategories={staticCategories}
      staticPopularProfiles={staticPopularProfiles}
      leftSidebar
      rightSideBar
    >
      {trendArticles && trendBooks && (
        <Home trendArticles={trendArticles} trendBooks={trendBooks} />
      )}
    </DefaultLayout>
  )
}

export default Page
